import { courtOrderAction } from "../actionType";
import { url } from "../../config/config";
import { isLoggedIn } from "../../utils";
import axios from "axios";

export const searchByWordInitiate = () => ({
  type: courtOrderAction.SEARCH_BY_WORD_INITIATE,
});
export const searchByWordSuccess = (data) => ({
  type: courtOrderAction.SEARCH_BY_WORD_SUCCESS,
  payload: data,
});
export const searchByWordFailure = (data) => ({
  type: courtOrderAction.SEARCH_BY_WORD_FAILURE,
});

export function fetchWordSearch(payload, type) {
  const token = isLoggedIn("clientLogin");

  return (dispatch) => {
    dispatch(searchByWordInitiate(payload));
    return new Promise((resolve, reject) =>
      axios
        .get(`${url}web/search_by_word`, {
          headers: { Authorization: `${token}` },
          params: payload,
        })
        .then((response) => {
          const data = response.data;
          if (data.status && data.status == 200) {
            dispatch(searchByWordSuccess(data));
          } else {
            dispatch(searchByWordFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(searchByWordFailure(err));
          reject(err);
        })
    );
  };
}

export const searchByLawInitiate = () => ({
  type: courtOrderAction.SEARCH_BY_LAW_INITIATE,
});
export const searchByLawSuccess = (data) => ({
  type: courtOrderAction.SEARCH_BY_LAW_SUCCESS,
  payload: data,
});
export const searchByLawFailure = (data) => ({
  type: courtOrderAction.SEARCH_BY_LAW_FAILURE,
});

export function fetchLawSearch(payload, type) {
  const token = isLoggedIn("clientLogin");

  return (dispatch) => {
    dispatch(searchByLawInitiate(payload));
    return new Promise((resolve, reject) =>
      axios
        .get(`${url}web/search_by_law`, {
          headers: { Authorization: `${token}` },
          params: payload,
        })
        .then((response) => {
          const data = response.data;
          if (data.status && data.status == 200) {
            dispatch(searchByLawSuccess(data));
          } else {
            dispatch(searchByLawFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(searchByLawFailure(err));
          reject(err);
        })
    );
  };
}

export const searchByCitationInitiate = () => ({
  type: courtOrderAction.SEARCH_BY_CITATION_INITIATE,
});
export const searchByCitationSuccess = (data) => ({
  type: courtOrderAction.SEARCH_BY_CITATION_SUCCESS,
  payload: data,
});
export const searchByCitationFailure = (data) => ({
  type: courtOrderAction.SEARCH_BY_CITATION_FAILURE,
});

export function fetchCitationSearch(payload, type) {
  const token = isLoggedIn("clientLogin");

  return (dispatch) => {
    dispatch(searchByCitationInitiate(payload));
    return new Promise((resolve, reject) =>
      axios
        .get(`${url}web/search_by_citation`, {
          headers: { Authorization: `${token}` },
          params: payload,
        })
        .then((response) => {
          const data = response.data;
          if (data.status && data.status == 200) {
            dispatch(searchByCitationSuccess(data));
          } else {
            dispatch(searchByCitationFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(searchByCitationFailure(err));
          reject(err);
        })
    );
  };
}

export const searchByPartyInitiate = () => ({
  type: courtOrderAction.SEARCH_BY_CITATION_INITIATE,
});
export const searchByPartySuccess = (data) => ({
  type: courtOrderAction.SEARCH_BY_CITATION_SUCCESS,
  payload: data,
});
export const searchByPartyFailure = (data) => ({
  type: courtOrderAction.SEARCH_BY_CITATION_FAILURE,
});

export function fetchPartySearch(payload, type) {
  const token = isLoggedIn("clientLogin");

  return (dispatch) => {
    dispatch(searchByPartyInitiate(payload));
    return new Promise((resolve, reject) =>
      axios
        .get(`${url}web/search_by_partyName`, {
          headers: { Authorization: `${token}` },
          params: payload,
        })
        .then((response) => {
          const data = response.data;
          if (data.status && data.status == 200) {
            dispatch(searchByPartySuccess(data));
          } else {
            dispatch(searchByPartyFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(searchByPartyFailure(err));
          reject(err);
        })
    );
  };
}


export const searchByCourtInitiate = () => ({
  type: courtOrderAction.SEARCH_BY_COURT_INITIATE,
});
export const searchByCourtSuccess = (data) => ({
  type: courtOrderAction.SEARCH_BY_CITATION_SUCCESS,
  payload: data,
});
export const searchByCourtFailure = (data) => ({
  type: courtOrderAction.SEARCH_BY_CITATION_FAILURE,
});

export function fetchCourtSearch(payload, type) {
  const token = isLoggedIn("clientLogin");

  return (dispatch) => {
    dispatch(searchByCourtInitiate(payload));
    return new Promise((resolve, reject) =>
      axios
        .get(`${url}web/search_by_courtName`, {
          headers: { Authorization: `${token}` },
          params: payload,
        })
        .then((response) => {
          const data = response.data;
          if (data.status && data.status == 200) {
            dispatch(searchByCourtSuccess(data));
          } else {
            dispatch(searchByCourtFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(searchByCourtFailure(err));
          reject(err);
        })
    );
  };
}



export const searchByCaseNumberInitiate = () => ({
  type: courtOrderAction.SEARCH_BY_CASE_NO_INITIATE,
});
export const searchByCaseNumberSuccess = (data) => ({
  type: courtOrderAction.SEARCH_BY_CASE_NO_SUCCESS,
  payload: data,
});
export const searchByCaseNumberFailure = (data) => ({
  type: courtOrderAction.SEARCH_BY_CASE_NO_FAILURE,
});

export function fetchCaseNumberSearch(payload, type) {
  const token = isLoggedIn("clientLogin");

  return (dispatch) => {
    dispatch(searchByCaseNumberInitiate(payload));
    return new Promise((resolve, reject) =>
      axios
        .get(`${url}web/search_by_caseNumber`, {
          headers: { Authorization: `${token}` },
          params: payload,
        })
        .then((response) => {
          const data = response.data;
          if (data.status && data.status == 200) {
            dispatch(searchByCaseNumberSuccess(data));
          } else {
            dispatch(searchByCaseNumberFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(searchByCaseNumberFailure(err));
          reject(err);
        })
    );
  };
}



export const searchByTopicInitiate = () => ({
  type: courtOrderAction.SEARCH_BY_TOPIC_INITIATE,
});
export const searchByTopicSuccess = (data) => ({
  type: courtOrderAction.SEARCH_BY_TOPIC_SUCCESS,
  payload: data,
});
export const searchByTopicFailure = (data) => ({
  type: courtOrderAction.SEARCH_BY_TOPIC_FAILURE,
});

export function fetchTopicSearch(payload, type) {
  const token = isLoggedIn("clientLogin");

  return (dispatch) => {
    dispatch(searchByTopicInitiate(payload));
    return new Promise((resolve, reject) =>
      axios
        .get(`${url}web/search_by_topic`, {
          headers: { Authorization: `${token}` },
          params: payload,
        })
        .then((response) => {
          const data = response.data;
          if (data.status && data.status == 200) {
            dispatch(searchByTopicSuccess(data));
          } else {
            dispatch(searchByTopicFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(searchByTopicFailure(err));
          reject(err);
        })
    );
  };
}


export const courtOrderCategoryDropDownInitiate = () => ({
  type: courtOrderAction.ORDER_CATEGORY_DROPDOWN_INITIATE,
});
export const courtOrderCategoryDropDownSuccess = (data) => ({
  type: courtOrderAction.ORDER_CATEGORY_DROPDOWN_SUCCESS,
  payload: data,
});
export const courtOrderCategoryDropDownFailure = (data) => ({
  type: courtOrderAction.ORDER_CATEGORY_DROPDOWN_FAILURE,
});

export function fetchcourtOrderCategoryDropDown(payload, type) {
  const token = isLoggedIn("clientLogin");

  return (dispatch) => {
    dispatch(courtOrderCategoryDropDownInitiate(payload));
    return new Promise((resolve, reject) =>
      axios
        .get(`${url}user/category_list`, {
          headers: { Authorization: `${token}` },
          params: payload,
        })
        .then((response) => {
          const data = response.data;
          if (data.status && data.status == 200) {
            dispatch(courtOrderCategoryDropDownSuccess(data));
          } else {
            dispatch(courtOrderCategoryDropDownFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(courtOrderCategoryDropDownFailure(err));
          reject(err);
        })
    );
  };
}



