import React, { useState } from "react";
import Header from "../header";
import Footer from "../footer";
import payment from "../../assets/images/payment.png";
import Modal from "react-bootstrap/Modal";
import success from "../../assets/images/Success.png"
import { Link } from "react-router-dom";

const ProceedToPay = () => {
  const [StatusModal, setStatusModal] = useState(false);
  const handleStatusModalClose = () => setStatusModal(false);
  const handleStatusModalShow = (id) => {
    setStatusModal(true);
  };

  return (
    <>
      <Header />

      <div class="payment-area">
        <div class="container">
          <a data-toggle="modal" onClick={()=> setStatusModal(true)}>
            <figure>
              <img src={payment} alt="" />
            </figure>
          </a>
        </div>
      </div>

      <Footer />

      <Modal
        show={StatusModal}
        onHide={handleStatusModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Category">
         
            <a
onClick={handleStatusModalClose}              class="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <figure>
              <img src={success} alt="" />
            </figure>
            <figcaption>
              <h1>Congratulations</h1>
              <p>
                Your 1 Year Subscription is activated. Now you can download and
                print all judgments.
              </p>
              <p>Subscription Expiry: 04/01/2025</p>
            </figcaption>
            <Link to="/subscribe-plan" class="Button">Continue</Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProceedToPay;
