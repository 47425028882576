import { authAction } from "../actionType"
const initialState = {
  clientData: {},
  otpData: {},
  otpForgotData: {},
  loader: false,
  loginStatus: false
}

export default function client(state = initialState, {type, payload}) {

  switch(type) {
    
    case authAction.OTP_INITIATE: {
      return {
        ...state, loader: true, otpData: {}
      }
    }
    case authAction.OTP_SUCCESS: {
      return {
        ...state, loader: false, otpData: payload.data
      }
    }
    case authAction.OTP_FAILURE: {
      return {
        ...state, loader: false
      }
    }

    case authAction.LOGIN_INITIATE: {
      return {
        ...state, loader: true, clientData: {}        
      }
    }
    case authAction.LOGIN_SUCCESS: {
      window.localStorage.setItem("clientLogin", JSON.stringify(payload.data))
      return {
        ...state, loader: false, loginStatus: true, clientData: payload.data
      }
    }
    case authAction.LOGIN_FAILURE: {
      return {
        ...state, loader: false
      }
    }

    case authAction.OTP_FORGOT_INITIATE: {
      return {
        ...state, loader: true, otpForgotData: {}
      }
    }
    case authAction.OTP_FORGOT_SUCCESS: {
      return {
        ...state, loader: false, otpForgotData: payload.data
      }
    }
    case authAction.OTP_FORGOT_FAILURE: {
      return {
        ...state, loader: false
      }
    }

    default: 
      return state
  }
}