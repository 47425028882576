import React, { useEffect } from "react";

import Header from "../header";
import Footer from "../footer";

import BlogRead from "../../assets/images/blog-read.png";
import Blog from "../../assets/images/blog11.png";
import MobileApp from "../../assets/images/mobile-app.png";
import Vector from "../../assets/images/Vector.png";
import Apple from "../../assets/images/apple.png";
import Android from "../../assets/images/android.png";
import { useLocation } from "react-router-dom";
import { dateTimeFormat } from "../helpers/dateTimeFormat";
import { useDispatch, useSelector } from "react-redux";
import { blogListing } from "../../redux/actions/blogAction";

const BlogDetail = () => {
  const { state } = useLocation();
  const dispatch = useDispatch()

  const getBlogData = useSelector((state) => state.blogs);
  const { catListData, tagsData, blogData } = getBlogData;


  useEffect(() => {
    window.scrollTo(0, 0);

    
    dispatch(blogListing({blog:"", category:"", keywords:""}))
  
  }, []);
  return (
    <>
      <section>
        <div className="blog-area">
          <div className="container">
            <div className="blog-header">
              <div className="row align-items-centre">
                <div className="col-12">
                  <figcaption>
                    <p>
                      <span>{state?.author}</span> •{" "}
                      {dateTimeFormat(state.createdAt)} <span>Design</span>
                    </p>
                    <h2>{state?.title}</h2>
                  </figcaption>
                  <figure>
                    <img src={state?.image} alt="blog read" />
                  </figure>
                </div>
              </div>
            </div>

            <div className="blog">
              <div className="row">
                <div className="col-8">
                  <div
                    className="blog-right"
                    dangerouslySetInnerHTML={{ __html: state?.blog }}
                  ></div>
                </div>

                <div className="col-4">
                  <div className="blog-left">
                    <h4>Recent blog posts</h4>

                    {blogData && blogData.result?.length > 0
                        ? blogData.result?.slice(0,3)?.map?.((item) => (

                    <div className="blog-card">
                      <figure>
                        <img src={item?.image} alt="blog" />
                      </figure>
                      <figcaption>
                        <h4>
                          <span>{item?.author}</span> •{" "}
                          {dateTimeFormat(item.createdAt)} <span>Design</span>
                        </h4>
                        <h3>{item?.title}</h3>
                        <p>{item?.blog?.slice(0, 150)}</p> <a>Read More</a>
                      </figcaption>
                      <div className="blog-button">
                        <button>Design</button>
                        <button>Research</button>
                      </div>
                    </div>)):null}
                  </div>
                  {/* <div className="blog-right">
                                        
                                        <div className="blog-card">
                                            <figure>
                                                <img src={Blog} alt="blog" />
                                            </figure>
                                            <figcaption>
                                                <h4><span>Poonam</span> • 1 Jan 2023</h4>
                                                <h3>MoU signed between IAMC, Hyderabad and TIAC, Uzbekistan...</h3>
                                                <p>IAMC, Hyderabad, in India entered into a Memorandum of Understanding <a href="blog-read.html">Read More</a></p>
                                            </figcaption>
                                            <div className="blog-button">
                                                <button>Design</button>
                                                <button>Research</button>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="mobile-app">
          <div className="container">
            <div className="mobile-app-right">
              <img src={MobileApp} alt="Mobile App" />
            </div>

            <div className="mobile-app-left">
              <span>
                <img src={Vector} alt="Vector" />
              </span>
              <h2>Download our mobile app</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />{" "}
                Nullam a volutpat lacus.
              </p>
              <div>
                <a href="#">
                  <img src={Apple} alt="apple" />
                </a>
                <a href="#">
                  <img src={Android} alt="Android" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogDetail;
