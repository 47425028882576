import React, { useState} from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";

import { passwordUpdate, profileLogout } from "../../redux/actions/profileAction";

import Header from "../header";
import Footer from "../footer";
import Sidebar from "../profile/sidebar";

import Dashboard from "../../assets/images/dashboard.png";
import Pass from "../../assets/images/pass.png";
import Logout from "../../assets/images/logout.png";

const initialState = {
    password:'',
    confirm_password:'',
    password_empty:'',
    confirm_password_empty:''
}
const MyAccountPassword = () => {
    const dispatch = useDispatch();

    const [userPass, setUserPass] = useState(initialState)
    const { password, confirm_password, password_empty, confirm_password_empty } = userPass;

    const handleInput = (e) => {
        const {name, value} = e.target
        setUserPass({...userPass, [name]:value})
    }

    const handleValidation = () => {
        let flag = true;
        let password_empty="";
        let confirm_password_empty="";

        if(!password) {
            flag = false
            password_empty = "Password field is required"
        } else {
            var flagStatus = passwordValidation(password);
            if(!flagStatus.flag) {
                flag = false
                password_empty = flagStatus.msg
            }
        }

        if(!confirm_password) {
            flag = false
            confirm_password_empty = "Confirm Password field is required"
        } else {
            if(password != confirm_password) {
                flag = false;
                confirm_password_empty = "Please make sure your password match.";
            }
        }

        setUserPass({...userPass, password_empty, confirm_password_empty})
        return flag;
    }

    function passwordValidation(password) {
        // regular expressions to validate password
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;
        var passwordValidation = "";
        var flag = true;

        if (!password.match(lowerCase)) {
            passwordValidation = "Password should contains lowercase letters!";
            flag = false;

        } else if (!password.match(upperCase)) {
            passwordValidation = "Password should contain uppercase letters!";
            flag = false;

        } else if (!password.match(numbers)) {
            passwordValidation = "Password should contains numbers also!";
            flag = false;

        } else if (password.length < 4) {
            passwordValidation = "Password length should be more than 4.";
            flag = false;
        }
        return {flag:flag, msg: passwordValidation};
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const checkValidation = handleValidation();
        if(checkValidation) {
            let reqData = {newPassword:password}
            dispatch(passwordUpdate(reqData))
            .then((res) => {
                if(res.status == 200) {
                    toast.success("Password updated successfully!")
                    setUserPass({...userPass, password:'', confirm_password:'', password_empty:'', confirm_password_empty:''})
                } else {
                    console.log(res)
                    toast.success("Something Went Wrong!")
                }
            })
            .catch((err) => { console.log(err) })   
        }
    }

    const handleLogout = (e) => {
        e.preventDefault();

        dispatch(profileLogout())
        .then((res) => {
            if(res.status == 200) {
                Navigate("/")
            } else {
                toast.success("Something Went Wrong!")
            }
        })
        .catch((err)=>{ 
            console.log(err) 
            if(err.response) {
                toast.error(err.response.data.message)
            }
        })
    }

    return (
        <>
            <Toaster position="top-right" gutter={8} containerClassName="mytoast" toastOptions={{ error:{duration: 3000, style:{background:'#be1313', color:'#fff'}}}} />
            <section>
                <div className="blog-area">
                    <div className="container">

                        <div className="blog">
                            <div className="row">
                                <div className="col-3">
                                    <div className="blog-left">
                                        <button className="Category-btn">My Account</button>
                                        <ul>
                                            <Sidebar />
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-9">

                                    <div className="my-account-right">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>New Password</label>
                                                        <input type="password" className="form-control" name="password" value={password} placeholder="Enter New Password" onChange={handleInput}/>
                                                        <span className="error">{password_empty}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Confirm Password</label>
                                                        <input type="password" className="form-control" name="confirm_password" value={confirm_password} placeholder="Enter Confirm Password" onChange={handleInput} />
                                                        <span className="error">{confirm_password_empty}</span>
                                                    </div>
                                                </div>
                                            </div>
                            
                                            <button type="submit" className="Button">Change Password</button>
                        
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default MyAccountPassword;