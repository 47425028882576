import { staticAction } from "../actionType";
import { url } from "../../config/config";
import {isLoggedIn} from "../../utils";
import axios from "axios";

export const contentInitiate = () => ({
    type: staticAction.CONTENT_INITIATE
})
export const contentSuccess = (data) => ({
    type: staticAction.CONTENT_SUCCESS,
    payload:data
})
export const contentFailure = (data) => ({
    type: staticAction.CONTENT_FAILURE
})

export function fetchcontent(payload,type) {

    return dispatch => {
       dispatch(contentInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.get(`${url}web/static/list?type=${payload.type}`)
                .then(response => {
                  const data = response.data
                    if (data.status && data.status == 200) {
                        dispatch(contentSuccess(data))
                    }
                    else{
                      dispatch(contentFailure(data))
                    }
                    resolve(data);
                })
                .catch(err => {
                    dispatch(contentFailure(err))
                    reject(err);
                })
        );
    }
}