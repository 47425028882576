import { legislationAction } from "../actionType";
import { url } from "../../config/config";
import { isLoggedIn } from "../../utils";
import axios from "axios";

export const getlegislationInitiate = () => ({
    type: legislationAction.SEARCH_BY_LEGISLATION_INITIATE,
  });
  export const getlegislationSuccess = (data) => ({
    type: legislationAction.SEARCH_BY_LEGISLATION_SUCCESS,
    payload: data,
  });
  export const getlegislationFailure = (data) => ({
    type: legislationAction.SEARCH_BY_LEGISLATION_FAILURE,
  });
  
  export function fetchSearchLegislation(payload, type) {
    const token = isLoggedIn("clientLogin");
  
    return (dispatch) => {
      dispatch(getlegislationInitiate(payload));
      return new Promise((resolve, reject) =>
        axios
          .get(`${url}web/search-by-legislation`, {
            headers: { Authorization: `${token}` },
            params: payload,
          })
          .then((response) => {
            const data = response.data;
            if (data.status && data.status == 200) {
              dispatch(getlegislationSuccess(data));
            } else {
              dispatch(getlegislationFailure(data));
            }
            resolve(data);
          })
          .catch((err) => {
            dispatch(getlegislationFailure(err));
            reject(err);
          })
      );
    };
  }
  
  

