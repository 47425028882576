import React,{ useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {sentOTP} from "../../redux/actions/authAction";

import Header from "../header";

import Login from "../../assets/images/login.png";
import OtpInput from "./otpInput";


const RegisterOTP = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const getOTPRes = useSelector(state => state.client)
    const { otpData } = getOTPRes

    const onOTPSubmit = (combineOtp="") => {
        if(combineOtp.length>0) {
            if(combineOtp == otpData.otp) {
                navigate("/signup/details");
            } else {
                toast.error('Verify OTP is Invalid!');    
            }
            //console.log("success", otpData)
        } else {
            toast.error('Verify OTP field is required');
        }
    }

    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(30);

    useEffect(() => {
        const interval = setInterval(() => {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
      
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(interval);
            } else {
              setSeconds(59);
              setMinutes(minutes - 1);
            }
          }
        }, 1000);
      
        return () => {
          clearInterval(interval);
        };
    }, [seconds]);

    const handleResend = (e) => {
        e.preventDefault()
        if(otpData.email) {
            const reqData = {email:otpData.email};
            dispatch(sentOTP(reqData))
            .then((res)=>{
                console.log(res);
                if(res.status == 200) {
                    setMinutes(1)
                    setSeconds(30)
                    toast.success('OTP has been sent on your email id');
                } else {
                    toast.error('Something went wrong! Please try after sometime.');
                }
            })
            .catch((err)=> {
                console.log(err)
                toast.error(err.response.data.message);
            })
        }
    }

    return (
        <>
            <Toaster position="top-right" gutter={8} containerClassName="mytoast" toastOptions={{ error:{duration: 3000, style:{background:'#be1313', color:'#fff'}}}} />
            <section>
                <div className="login-page">
                    <div className="login-left">
                        <img src={Login} alt="Login" />
                    </div>
                    <div className="login-right">

                        <figcaption className="#">
                            <h1>Verify OTP</h1>
                            <p>Please enter 4 Digit OTP sent to your email id. {otpData?.otp}</p>
                        </figcaption>

                        <form>  
                            <div className="form-group">
                                <div className="OTPBox">
                                    <OtpInput length={4} onOTPSubmit={onOTPSubmit} />
                                </div>

                                {seconds > 0 || minutes > 0 ? (
                                    <p>
                                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                    </p>
                                ) : (
                                    <p>Didn't recieve code?</p>
                                )}

                                <p className="text-left">
                                    <a 
                                        disabled={seconds > 0 || minutes > 0}
                                        // style={{
                                        //     color: seconds > 0 || minutes > 0 ? "#000" : "#fff",
                                        //     backgroundColor: seconds > 0 || minutes > 0 ? "#ddd" : "#077CA1",
                                        // }}
                                        type="button" 
                                        onClick={handleResend}>
                                            Resend OTP
                                    </a>
                                </p>
                            </div> 
                            
                            <a href="login-register-new.html" className="button">Start Free Access</a>
                        </form>
                    </div>
                </div>
            </section>
            
        </>
    );
}

export default RegisterOTP