import React from "react";

import Logo from "../assets/images/logo.png";
import FB from "../assets/images/fb.png";
import Twitter from "../assets/images/twitter.png";
import Insta from "../assets/images/insta.png";
import Linkedin from "../assets/images/linkedin.png";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="Footer">
                <div className="container">
                    <div className="Footer-area">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="Footer-1">
                                    <figure><img src={Logo} alt="Logo"/></figure>     
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a volutpat</p>                    
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="Footer-2">
                                    <ul>
                                        <li><Link to="/support">Support</Link></li>
                                        <li><Link to="/terms">Term & Conditions</Link></li>
                                        <li><Link to="/policy">Legal Policy</Link></li>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="Footer-3">
                                    <div>
                                        <p>Follow Us</p>
                                        <ul>
                                            <li><a href="javascript:void(0);"><img src={FB} alt="facebook" /></a></li>
                                            <li><a href="javascript:void(0);"><img src={Twitter} alt="Twitter" /></a></li>
                                            <li><a href="javascript:void(0);"><img src={Insta} alt="Instagaram" /></a></li>
                                            <li><a href="javascript:void(0);"><img src={Linkedin} alt="LinkedIn" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="CoprRight">
                        <figcaption>
                            <p>© 2023.All Rights Reserved. Sarel</p>
                        </figcaption>
                    </div>
                </div>
            </div>
            
        </footer>
    );
}

export default Footer