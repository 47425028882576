import { subscriptionAction } from "../actionType";
import { url } from "../../config/config";
import { isLoggedIn } from "../../utils";
import axios from "axios";

export const getAllPlanInitiate = () => ({
    type: subscriptionAction.GET_ALL_PLAN_INITIATE,
  });
  export const getAllPlanSuccess = (data) => ({
    type: subscriptionAction.GET_ALL_PLAN_SUCCESS,
    payload: data,
  });
  export const getAllPlanFailure = (data) => ({
    type: subscriptionAction.GET_ALL_PLAN_FAILURE,
  });
  
  export function fetchAllPlan(payload, type) {
    const token = isLoggedIn("clientLogin");
  
    return (dispatch) => {
      dispatch(getAllPlanInitiate(payload));
      return new Promise((resolve, reject) =>
        axios
          .get(`${url}web/plan/list`, {
            headers: { Authorization: `${token}` },
            params: payload,
          })
          .then((response) => {
            const data = response.data;
            if (data.status && data.status == 200) {
              dispatch(getAllPlanSuccess(data));
            } else {
              dispatch(getAllPlanFailure(data));
            }
            resolve(data);
          })
          .catch((err) => {
            dispatch(getAllPlanFailure(err));
            reject(err);
          })
      );
    };
  }
  
  


  export const getCurrentPlanInitiate = () => ({
    type: subscriptionAction.GET_CURRENT_PLAN_INITIATE,
  });
  export const getCurrentPlanSuccess = (data) => ({
    type: subscriptionAction.GET_CURRENT_PLAN_SUCCESS,
    payload: data,
  });
  export const getCurrentPlanFailure = (data) => ({
    type: subscriptionAction.GET_CURRENT_PLAN_FAILURE,
  });
  
  export function fetchCurrentPlan(payload, type) {
    const token = isLoggedIn("clientLogin");
  
    return (dispatch) => {
      dispatch(getCurrentPlanInitiate(payload));
      return new Promise((resolve, reject) =>
        axios
          .get(`${url}web/plan/current-plan`, {
            headers: { Authorization: `${token}` },
            params: payload,
          })
          .then((response) => {
            const data = response.data;
            if (data.status && data.status == 200) {
              dispatch(getCurrentPlanSuccess(data));
            } else {
              dispatch(getCurrentPlanFailure(data));
            }
            resolve(data);
          })
          .catch((err) => {
            dispatch(getCurrentPlanFailure(err));
            reject(err);
          })
      );
    };
  }
  



  export const purchasePlanInitiate = () => ({
    type: subscriptionAction.PURCHASE_PLAN_INITIATE,
  });
  export const purchasePlanSuccess = (data) => ({
    type: subscriptionAction.PURCHASE_PLAN_SUCCESS,
    payload: data,
  });
  export const purchasePlanFailure = (data) => ({
    type: subscriptionAction.PURCHASE_PLAN_FAILURE,
  });
  
  export function purchasePlanAction(payload,type) {    
    const token = isLoggedIn('clientLogin')

    return dispatch => {
       dispatch(purchasePlanInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.post(`${url}web/plan/purchase-plan`, payload, {
                headers: { Authorization: `${token}` }
            })
            .then(response => {
                const data = response.data
                if (data.status && data.status == 200) {
                    dispatch(purchasePlanSuccess(data))
                }
                else{
                    dispatch(purchasePlanFailure(data))
                }
                resolve(data);
            })
            .catch(err => {
                dispatch(purchasePlanFailure(err))
                reject(err);
            })
        );
    }
}



export const getPlanHistoryInitiate = () => ({
  type: subscriptionAction.GET_PLAN_HISTORY_INITIATE,
});
export const getPlanHistorySuccess = (data) => ({
  type: subscriptionAction.GET_PLAN_HISTORY_SUCCESS,
  payload: data,
});
export const getPlanHistoryFailure = (data) => ({
  type: subscriptionAction.GET_PLAN_HISTORY_FAILURE,
});

export function fetchPlanHistory(payload, type) {
  const token = isLoggedIn("clientLogin");

  return (dispatch) => {
    dispatch(getPlanHistoryInitiate(payload));
    return new Promise((resolve, reject) =>
      axios
        .get(`${url}web/plan/history`, {
          headers: { Authorization: `${token}` },
          params: payload,
        })
        .then((response) => {
          const data = response.data;
          if (data.status && data.status == 200) {
            dispatch(getPlanHistorySuccess(data));
          } else {
            dispatch(getPlanHistoryFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(getPlanHistoryFailure(err));
          reject(err);
        })
    );
  };
}

