import React, { useState } from "react";
import Header from "../header";
import Footer from "../footer";
import toast, { Toaster } from "react-hot-toast";
import { Country, State, City } from "country-state-city";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { purchasePlanAction } from "../../redux/actions/subscriptionAction";

const initialState = {
  first_name: "",
  last_name: "",
  mobile: "",
  country: "",
  countryCode: "",
  province: "",
  provinceCode: "",
  city: "",
  first_name_empty: "",
  last_name_empty: "",
  mobile_empty: "",
  country_empty: "",
  province_empty: "",
  city_empty: "",
  address_empty: "",
  code_empty: "",
  email: "",
  email_empty: "",
  email_invalid: "",
  organization: "",
  designation: "",
  address: "",
  code: "",
};
const SubscribeRenewPlan = () => 
{
  const [iState, updateState] = useState(initialState);
  const [isEnabled, setIsEnabled] = useState(false)

  const [isEnabledTerms, setIsEnabledTerms] = useState(false);
  const navigate = useNavigate();

  const { state } = useLocation();

  const dispatch = useDispatch();

  const [countryDropdown, setCountryDropdown] = useState(
    Country.getAllCountries()
  );

  const [stateDropdown, setStateDropdown] = useState(State.getAllStates());
  const [stateDropdownList, setStateDropdownList] = useState([]);

  const [cityDropdown, setCityDropdown] = useState(City.getAllCities());
  const [cityDropdownList, setCityDropdownList] = useState([]);

  const {
    first_name,
    last_name,
    mobile,
    country,
    countryCode,
    province,
    provinceCode,
    city,
    first_name_empty,
    last_name_empty,
    mobile_empty,
    country_empty,
    province_empty,
    city_empty,
    email,
    email_empty,
    email_invalid,
    address,
    code,
    address_empty,
    code_empty,
    designation,
    organization,
  } = iState;

  const handleTermsCheck = (e) => {
    const { name, value } = e.target;
    if (isEnabledTerms) {
      setIsEnabledTerms(false);
    } else {
      setIsEnabledTerms(true);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "country") {
      let getCountry = countryDropdown.filter((item) => {
        return item.isoCode == value;
      });
      updateState({
        ...iState,
        [name]: getCountry[0]["name"],
        countryCode: getCountry[0]["isoCode"],
      });

      let stateResult = stateDropdown.filter((item) => {
        return item.countryCode == value;
      });
      setStateDropdownList(stateResult);
    } else if (name === "province") {
      let getState = stateDropdown.filter((item) => {
        return item.isoCode == value;
      });

      updateState({
        ...iState,
        [name]: getState[0]["name"],
        stateCode: getState[0]["isoCode"],
      });

      let cityResult = cityDropdown.filter((item) => {
        return item.stateCode == value;
      });
      setCityDropdownList(cityResult);
    } else {
      updateState({ ...iState, [name]: value });
    }
  };

  const handleValidation = () => {
    let flag = true;
    let first_name_empty = "";
    let last_name_empty = "";
    let mobile_empty = "";
    let country_empty = "";
    let province_empty = "";
    let city_empty = "";
    let address_empty = "";
    let code_empty = "";

    if(isEnabled == false) {
      flag = false;
      toast.error('Please accept the Terms and Condition');
      return false;
  }


    if (!first_name) {
      console.log("FFFFFFFFFFFFF");
      flag = false;
      first_name_empty = "First name field is requried";
    } else {
      if (!/\S/.test(first_name)) {
        flag = false;
        first_name_empty = "First name field is requried";
      }
    }
    if (!last_name) {
      flag = false;
      last_name_empty = "Last name field is requried";
    } else {
      if (!/\S/.test(last_name)) {
        flag = false;
        last_name_empty = "Last name field is requried";
      }
    }

    if (!mobile) {
      flag = false;
      mobile_empty = "Mobile field is requried";
    }
    if (/^\d*$/.test(mobile)) {
    } else {
      flag = false;
      mobile_empty = "Please enter Numeric value";
    }

    if (!country) {
      flag = false;
      country_empty = "Country field is requried";
    }

    if (!province) {
      flag = false;
      province_empty = "State field is requried";
    }

    if (!city) {
      flag = false;
      city_empty = "City field is requried";
    }
    if (!address) {
      flag = false;
      address_empty = "Address field is requried";
    }

    if (!code) {
      flag = false;
      code_empty = "Postal code field is requried";
    }

    if (flag) {
      if (!isEnabledTerms) {
        flag = false;
        toast.error("Please accept the Terms and Condition");
      }
    }
    updateState({
      ...iState,
      first_name_empty: first_name_empty,
      last_name_empty: last_name_empty,
      mobile_empty: mobile_empty,
      country_empty: country_empty,
      province_empty: province_empty,
      city_empty: city_empty,
      code_empty: code_empty,
      address_empty: address_empty,
    });
    return flag;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let checkValidation = handleValidation();
    if (checkValidation) {
      let data = {
        planId: state?._id,
        personalDetails: {
          firstName: first_name,
          lastName: last_name,
          email: email,
          contact: mobile,
        },
        billingDetails: {
          address: address,
          code: code,

          country: country,
          city: city,
          state: province,
          designation: designation,
          organization: organization,
        },
        paymentDetails: {
          paymentOptions: "fullpayment",
        },
        checkOut: {
          paymentType: "",
        },
        paymentStatus: "confirmed",
      };

      dispatch(purchasePlanAction(data))
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            navigate("/proceed-to-pay");
          } else {
            toast.error("Something went wrong! Please try after sometime.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  console.log(state, "PPPPPPPPPPPP");

  return (
    <>
                <Toaster position="top-right" gutter={8} containerClassName="mytoast" toastOptions={{ error:{duration: 3000, style:{background:'#be1313', color:'#fff'}}}} />

      <Header />
      

      <section>
        <div class="renew">
          <div class="container">
            <h3>Subscription Plan</h3>

            <div class="renew-body">
              <div class="row">
                <div class="col-8">
                  <div class="renew-area-left">
                    <aside>
                      <h4>1 Year Plan</h4>
                      <h2>₹{state?.actualPrice}</h2>
                      <p>/ Per year (excluding gst)</p>
                    </aside>
                    <article>
                      <ul>
                        <li>
                          <p>
                            <span>
                              <img src="images/white-tick.png" alt="" />
                            </span>{" "}
                            Lorem ipsum dolor sit
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>
                              <img src="images/white-tick.png" alt="" />
                            </span>{" "}
                            Lorem ipsum dolor sit
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>
                              <img src="images/white-tick.png" alt="" />
                            </span>{" "}
                            Lorem ipsum dolor sit
                          </p>
                        </li>
                      </ul>
                    </article>
                  </div>

                  <div class="renew-plan">
                    <form onSubmit={handleSubmit}>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              value={first_name}
                              placeholder="Enter First Name"
                              onChange={handleInput}
                            />
                            <span className="error">{first_name_empty}</span>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="last_name"
                              value={last_name}
                              placeholder="Enter Last Name"
                              onChange={handleInput}
                            />
                            <span className="error">{last_name_empty}</span>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label>Email ID</label>
                            <input
                              type="text"
                              name="email"
                              value={email}
                              className="form-control"
                              placeholder="Enter Email ID"
                              onChange={(e) => handleInput(e)}
                            />
                            <span className="error">{email_empty}</span>
                            {email_invalid && (
                              <span className="error">{email_invalid}</span>
                            )}
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label>Mobile Number</label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={12}
                              name="mobile"
                              value={mobile}
                              placeholder="Enter Mobile Number"
                              onChange={handleInput}
                            />
                            <span className="error">{mobile_empty}</span>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label>Billing Address</label>
                            <input
                              type="text"
                              class="form-control"
                              value={address}
                              name="address"
                              onChange={handleInput}
                              placeholder="Enter Billing Address"
                            />
                            <span className="error">{address_empty}</span>
                          </div>
                        </div>

                        <div class="col-3">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={code}
                              name="code"
                              onChange={handleInput}

                              placeholder="Enter Postal Code"
                            />

                            <span className="error">{code_empty}</span>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group">
                            <select
                              className="form-control"
                              name="country"
                              onChange={handleInput}
                            >
                              <option value="">--Select--</option>
                              {countryDropdown &&
                                countryDropdown?.filter((ele)=>ele.name =="India"|| ele.name =="Bangladesh"|| ele.name =="Pakistan"|| ele.name =="Bhutan"|| ele.name =="Srilanka"|| ele.name =="Nepal").map((item, index) => {
                                  //return console.log(item.name)
                                  return (
                                    <option key={index} value={item.isoCode}>
                                      {" "}
                                      {item.name}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <span className="error">{country_empty}</span>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group">
                            <select
                              className="form-control"
                              name="province"
                              onChange={handleInput}
                            >
                              <option value="">--Select--</option>
                              {stateDropdownList &&
                                stateDropdownList.map((item, index) => {
                                  return (
                                    <option
                                      key={index}
                                      data-name={item.name}
                                      value={item.isoCode}
                                    >
                                      {" "}
                                      {item.name}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <span className="error">{province_empty}</span>{" "}
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group">
                            <select
                              className="form-control"
                              name="city"
                              onChange={handleInput}
                            >
                              <option value="">--Select--</option>
                              {cityDropdownList &&
                                cityDropdownList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {" "}
                                      {item.name}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <span className="error">{city_empty}</span>{" "}
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label>Organization (optional)</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Designation (optional)"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label>Organization (optional)</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Organization (optional)"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="col-4">
                  <div class="renew-area-right">
                    <button>1 Year Plan</button>

                    <div class="price-details">
                      <h4>Price Details</h4>
                      <aside>
                        <figcaption>
                          <h5>Base Fare </h5>
                          <p>₹984</p>
                        </figcaption>
                        <figcaption>
                          <h5>GST (18%)</h5>
                          <p>₹216</p>
                        </figcaption>
                        <figcaption>
                          <h5>Total</h5>
                          <p>₹1200</p>
                        </figcaption>
                      </aside>

                      <article>
                        <div class="form-group">
                          <label class="CheckBox">
                            I have read <span>Terms &amp; conditions</span>{" "}
                            &amp; <span>Cancellation Policies</span>
                            <input
                              type="checkbox"
                              onChange={handleTermsCheck}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </article>

                      <h4>Payment Options</h4>
                      <ul class="Avaialable Avaialable1 renew-payment">
                        <li>
                          <label class="Radio">
                            Monthly Payment
                            <input type="radio" name="ac" />
                            <span class="checkmark"></span>
                          </label>
                          <p>
                            <b>₹100</b>
                          </p>
                        </li>
                        <li>
                          <label class="Radio">
                            Quarterly Payment
                            <input type="radio" name="ac" />
                            <span class="checkmark"></span>
                          </label>
                          <p>
                            <b>₹300</b>
                          </p>
                        </li>
                        <li>
                          <label class="Radio">
                            Half Yearly Payment
                            <input type="radio" name="ac" />
                            <span class="checkmark"></span>
                          </label>
                          <p>
                            <b>₹600</b>
                          </p>
                        </li>
                        <li>
                          <label class="Radio">
                            Full Paytment
                            <input type="radio" name="ac" />
                            <span class="checkmark"></span>
                          </label>
                          <p>
                            <b>₹1200</b>
                          </p>
                        </li>
                      </ul>

                      <button onClick={handleSubmit} className="button">
                        Proceed to Pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SubscribeRenewPlan;
