import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import { fetchCategory } from "../redux/actions/blogAction";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { Country }  from 'country-state-city';
import { fetchSearchLegislation } from "../redux/actions/legislationAction";
import { useNavigate } from "react-router-dom";
import { fetchcourtOrderCategoryDropDown } from "../redux/actions/courtOrderAction";
import { filter } from "lodash";


const initailState = {
  keywords: "",

  countryName: "",

  legislationType: "",

  category: "",
};

const Legislation = () => {
  const [searchState, setSearchSate] =useState(false)

  const getProfileData = useSelector(state => state.profile)
  const {clientData} = getProfileData
  const [countryDropdown, setCountryDropdown] = useState(Country.getAllCountries());
const dispatch = useDispatch()
const navigate = useNavigate()

  const [iState, updateState] = useState(initailState);

  const {keywords, category,countryName,legislationType}=iState

  const {orderCategory} = useSelector((state) => state.courtOrderReducer);

  useEffect(() => {
    dispatch(fetchcourtOrderCategoryDropDown())
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };


  const handleSubmit =(e)=>{
    e.preventDefault();


    let data ={
      keywords, category,countryName

    }


    if(Object.keys(clientData).length !== 0){



    dispatch(fetchSearchLegislation(data))
    .then((res)=>{
        console.log(res);
        if(res.status == 200) {
          
            navigate("/legislation-tabs" , {state:res.data});
        } else {
            toast.error('Something went wrong! Please try after sometime.');
        }
    })
    .catch((err)=> {
        console.log(err)
    })
  }
  else{
    navigate('/login')
  }
}



  


  return (
    <>

      <section>
        <div class="renew">
          <div class="container">
            <div class="renew-body">
              <div class="row">
                <div class="col-4">
                  <div class="FindActsArea" style={{ height: "100%" }}>
                    <h5>Find Acts, Rules &amp; Notifications</h5>
                    <p>
                      Search for Acts, Rules &amp; Notifications in India,
                      Bangladesh, Pakistan, Sri Lanka, Nepal, Bhutan.”
                    </p>
                    <small>
                      Select the country name, specify the topic/category, and
                      apply filters to search for acts, rules, or
                      notifications/office memoranda.
                    </small>
                  </div>
                </div>

                <div class="col-8">
                  <div class="renew-plan" style={{ height: "100%" }}>
                    <h3>Search Legislation</h3>
                    <form>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label>Country</label>
                            <select className="form-control" name="countryName" onChange={handleChange}>
                                            <option value="">--Select--</option>
                                            {
                                                countryDropdown && countryDropdown?.filter((ele)=>ele.name =="India"|| ele.name =="Bangladesh"|| ele.name =="Pakistan"|| ele.name =="Bhutan"|| ele.name =="Srilanka"|| ele.name =="Nepal").map((item , index) => {
                                                    //return console.log(item.name)
                                                    return <option key={index} value={ item.name }> { item.name } </option>
                                                })
                                            }
                                        </select>

                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label>Category (Optional)</label>
                            <select
                              value={category}
                              name="category"
                              onChange={handleChange}
                              class="form-control"
                            >
                              <option value={""}>--Select--</option>
                              {orderCategory && orderCategory.length > 0
                            ? orderCategory.map((item) => (
                                <option value={item?.name}>{item?.name}</option>
                              ))
                            : null}
                            </select>{" "}
                          </div>
                        </div>
                      </div>
                      <div
                        class="environmental"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <div class="environmental-bottom p-0">
                          <article
                            style={{
                              width: "100%",
                              paddingLeft: "0px",
                              backgroundColor: "transparent",
                            }}
                          >
                            <div class="row">
                              <div class="col-9">
                                <div class="form-group">
                                  <input
                                   value={keywords}
                                   name="keywords"
                                   onChange={handleChange}
                                    type="search"
                                    class="form-control"
                                    placeholder="Type your keyword"
                                  />
                                </div>
                              </div>
                              <div class="col-3">
                                <a onClick={handleSubmit} class="Button">
                                  Search{" "}
                                  <span>
                                    <img src="images/search.png" alt="" />
                                  </span>
                                </a>
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Legislation;
