import React from "react";
import { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';

import {  profileLogout } from "../redux/actions/profileAction";

import Logo from "../assets/images/logo.png";
import search from "../assets/images/search.png";

const Header = ({searchState,setSearchSate}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const getProfileData = useSelector(state => state.profile)
    const {loginStatus} = useSelector(state => state.client)

    const {clientData ,loader} = getProfileData
  


    const handleJudgement =()=>{

         
          
        navigate('/',{state:"top"})

    }




    const handleLogout = (e) => {
        e.preventDefault();
    
        dispatch(profileLogout())
        .then((res) => {
            if(res.status == 200) {
                window.location.href="http://3.13.196.242/legal_web/"
            } else {
                toast.success("Something Went Wrong!")
            }
        })
        .catch((err)=>{ 
            console.log(err) 
            if(err.response) {
                toast.error(err.response.data.message)
            }
        })

    }


    return (
        <header>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <a className="navbar-brand" href="index.html">
                        <img src={Logo} alt="Logo" />
                    </a>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/">Home</NavLink>
                            </li>
                         

                          <li className="nav-item">
                                <a onClick={handleJudgement} className="nav-link" >Judgments</a>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/legislation">Legislations</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/blog">Blogs</NavLink>
                            </li>
                            
                            {/* {
                               Object.keys(clientData).length == 0 ?"": <li className="nav-item">
                                <NavLink className="nav-link" to="/subscribe-plan">Subscribe</NavLink>
                            </li>} */}
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/about">About</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/contact">Contact</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink className="nav-link" ><img src={search} alt="search" /></NavLink>
                            </li> */}
                            
                        </ul>
                        {!loader && <ul className="navbar-nav Login"> 
                            {
                              loginStatus?
                            
                            
                                // <li><NavLink to="/profile" className="Member-icon">My Account</NavLink></li>

                                <div class="Main-User">
                      
                        <div class="Avater">
                            <a href="javascript:void(0);"><img style={{height:"30px"}} src={ clientData?.result?.[0]?.image ==""?require("../assets/images/user.png"):  clientData?.result?.[0]?.image } alt="" /></a>
                            <ul>
                                <li>
                                    <figure><img src={ clientData?.result?.[0]?.image ==""?require("../assets/images/user.png"):  clientData?.result?.[0]?.image } /></figure>
                                    <h4> {clientData?.result?.[0]?.firstName}</h4>
                                <p>{clientData?.result?.[0]?.email}</p>
                                </li>
                                <li>
                                    <Link to="/profile">
                                        My Account
                                    </Link>
                                    <a onClick={handleLogout} class="active">
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>:    <>
                                <li><NavLink to="/login" className="Member-icon">Login</NavLink></li>
                                <li><NavLink to="/signup" className="Button">Start Free Trial</NavLink></li>
                            </>

                               
                            }
                        </ul>  }
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header