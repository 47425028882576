import { courtOrderAction } from "../actionType"

const initialState = {
    orderCategory: {},
    loader: false,
    loginStatus: false
}

export default function courtOrderReducer(state = initialState, {type, payload}) {
  switch(type) {
    
    case courtOrderAction.ORDER_CATEGORY_DROPDOWN_INITIATE: {
      return {
        ...state, loader: true, orderCategory: {}
      }
    }
    case courtOrderAction.ORDER_CATEGORY_DROPDOWN_SUCCESS: {
      return {
        ...state, loader: false, orderCategory: payload?.data
      }
    }
    case courtOrderAction.ORDER_CATEGORY_DROPDOWN_FAILURE: {
      return {
        ...state, loader: false
      }
    }


    default: 
      return state
  }
}