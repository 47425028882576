import { authAction } from "../actionType";
import { url } from "../../config/config";
import {isLoggedIn} from "../../utils";
import axios from "axios";

export const otpInitiate = () => ({
    type: authAction.OTP_INITIATE
})
export const otpSuccess = (data) => ({
    type: authAction.OTP_SUCCESS,
    payload:data
})
export const otpFailure = (data) => ({
    type: authAction.OTP_FAILURE
})

export const registerInitiate = () => ({
    type: authAction.REGISTER_INITIATE
})
export const registerSuccess = (data) => ({
    type: authAction.REGISTER_SUCCESS,
    payload:data
})
export const registerFailure = (data) => ({
    type: authAction.REGISTER_FAILURE
})

export const loginInitiate = () => ({
    type: authAction.LOGIN_INITIATE
})
export const loginSuccess = (data) => ({
    type: authAction.LOGIN_SUCCESS,
    payload:data
})
export const loginFailure = (data) => ({
    type: authAction.LOGIN_FAILURE
})

export const otpforgotInitiate = () => ({
    type: authAction.OTP_FORGOT_INITIATE
})
export const otpforgotSuccess = (data) => ({
    type: authAction.OTP_FORGOT_SUCCESS,
    payload:data
})
export const otpforgotFailure = (data) => ({
    type: authAction.OTP_FORGOT_FAILURE
})

export function sentOTP(payload,type) {    
    return dispatch => {
       dispatch(otpInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.post(`${url}web/sent_otp`, payload)
                .then(response => {
                  const data = response.data
                  
                    if (data.status && data.status == 200) {
                        dispatch(otpSuccess(data))
                    }
                    else{
                      dispatch(otpFailure(data))
                    }
                    resolve(data);
                })
                .catch(err => {
                    dispatch(otpFailure(err))
                    reject(err);
                })
        );
    }
}

export function register(payload,type) {    
    return dispatch => {
       dispatch(registerInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.post(`${url}web/signup`, payload)
                .then(response => {
                  const data = response.data
                    if (data.status && data.status == 200) {
                        dispatch(registerSuccess(data))
                    }
                    else{
                      dispatch(registerFailure(data))
                    }
                    resolve(data);
                })
                .catch(err => {
                    dispatch(registerFailure(err))
                    reject(err);
                })
        );
    }
}

export function login(payload,type) {
    
    return dispatch => {
       dispatch(loginInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.post(`${url}user/login`, payload)
                .then(response => {
                  const data = response.data
                    if (data.status && data.status == 200) {
                        dispatch(loginSuccess(data))
                    }
                    else{
                      dispatch(loginFailure(data))
                    }
                    resolve(data);
                })
                .catch(err => {
                    dispatch(loginFailure(err))
                    reject(err);
                })
        );
    }
}

export function sentForgotOTP(payload,type) {    
    return dispatch => {
       dispatch(otpforgotInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.post(`${url}web/reset_password_otp`, payload)
                .then(response => {
                  const data = response.data

                    if (data.status && data.status == 200) {

                        dispatch(otpforgotSuccess(data))
                    }
                    else{
                      dispatch(otpforgotFailure(data))
                    }
                    resolve(data);
                })
                .catch(err => {
                    dispatch(otpforgotFailure(err))
                    reject(err);
                })
        );
    }
}

export function forgot(payload,type) {    
    return dispatch => {
       dispatch(otpforgotInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.post(`${url}web/reset_password`, payload)
                .then(response => {
                  const data = response.data
                  
                    if (data.status && data.status == 200) {
                        dispatch(otpforgotSuccess(data))
                    }
                    else{
                      dispatch(otpforgotFailure(data))
                    }
                    resolve(data);
                })
                .catch(err => {
                    dispatch(otpforgotFailure(err))
                    reject(err);
                })
        );
    }
}