import React from "react";
import Footer from "./footer";
import { useLocation } from "react-router-dom";

import download from "../assets/images/download-new.png";

const LegislationTabs = () => {
  const { state } = useLocation();

  return (
    <>

      <section>
        <div class="LegislationTabsArea">
          <div class="container">
            <ul class="nav nav-tabs">
              <li class="nav-item active">
                <a href="#ActsTab" class="nav-link active" data-toggle="tab">
                  Acts
                </a>
              </li>
              <li class="nav-item">
                <a href="#RulesTab" class="nav-link" data-toggle="tab">
                  Rules
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#NotificationsOfficeMemoranda"
                  class="nav-link"
                  data-toggle="tab"
                >
                  Notifications/Office Memoranda
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane show fade active" id="ActsTab">
                <div class="LegislationContentTable">
                  <h6>You searched for : Keyword (No. of Results)</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Enactment Date</th>
                        <th>Act No.</th>
                        <th>Title of Act</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state?.result
                        ?.filter((ele) => ele?.legislationType == "acts")
                        ?.map((item) => (
                          <tr>
                            <td>{item?.enactmentDate}</td>
                            <td>{item?.actNo}</td>
                            <td>{item?.titleOfAct}</td>
                            <td>
                              <div class="Actions">
                                <a href={item?.courtorderPdf} target="_blank">
                                  <img src={download} />
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="RulesTab">
                <div class="LegislationContentTable">
                  <h6>You searched for : Keyword (No. of Results)</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Year</th>
                        <th>Description</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state?.result
                        ?.filter((ele) => ele?.legislationType == "rules")
                        ?.map((item) => (
                          <tr>
                            <td>{item?.enactmentDate}</td>
                            <td>{item?.actNo}</td>
                            <td>
                              <div class="Actions">
                                <a href={item?.courtorderPdf} target="_blank">
                                  <img src={download} />
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="NotificationsOfficeMemoranda">
                <div class="LegislationContentTable">
                  <h6>You searched for : Keyword (No. of Results)</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Year</th>
                        <th>Description</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state?.result
                        ?.filter(
                          (ele) => ele?.legislationType == "notifications"
                        )
                        ?.map((item) => (
                          <tr>
                            <td>{item?.enactmentDate}</td>
                            <td>{item?.actNo}</td>
                            <td>
                              <div class="Actions">
                                <a href={item?.courtorderPdf} target="_blank">
                                  <img src={download} />
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LegislationTabs;
