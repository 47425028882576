export const authAction = {
  LOGIN_INITIATE: "LOGIN_INITIATE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  OTP_INITIATE: "OTP_INITIATE",
  OTP_SUCCESS: "OTP_SUCCESS",
  OTP_FAILURE: "OTP_FAILURE",

  REGISTER_INITIATE: "REGISTER_INITIATE",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",

  REGISTER_OTP_INITIATE: "REGISTER_OTP_INITIATE",
  REGISTER_OTP_SUCCESS: "REGISTER_OTP_SUCCESS",
  REGISTER_OTP_FAILURE: "REGISTER_OTP_FAILURE",

  REGISTER_DETAIL_INITIATE: "REGISTER_DETAIL_INITIATE",
  REGISTER_DETAIL_SUCCESS: "REGISTER_DETAIL_SUCCESS",
  REGISTER_DETAIL_FAILURE: "REGISTER_DETAIL_FAILURE",

  GET_DETAILS_INITIATE: "GET_DETAILS_INITIATE",
  GET_DETAILS_SUCCESS: "GET_DETAILS_SUCCESS",
  GET_DETAILS_FAILURE: "GET_DETAILS_FAILURE",

  UPDATE_INITIATE: "UPDATE_INITIATE",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FAILURE: "UPDATE_FAILURE",

  PASSWORD_UPDATE_INITIATE: "PASSWORD_UPDATE_INITIATE",
  PASSWORD_UPDATE_SUCCESS: "PASSWORD_UPDATE_SUCCESS",
  PASSWORD_UPDATE_FAILURE: "PASSWORD_UPDATE_FAILURE",

  OTP_FORGOT_INITIATE: "OTP_FORGOT_INITIATE",
  OTP_FORGOT_SUCCESS: "OTP_FORGOT_SUCCESS",
  OTP_FORGOT_FAILURE: "OTP_FORGOT_FAILURE",
};

export const profileAction = {
  DETAIL_INITIATE: "DETAIL_INITIATE",
  DETAIL_SUCCESS: "DETAIL_SUCCESS",
  DETAIL_FAILURE: "DETAIL_FAILURE",

  LOGOUT_INITIATE: "LOGOUT_INITIATE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  PASSWORD_INITIATE: "PASSWORD_INITIATE",
  PASSWORD_SUCCESS: "PASSWORD_SUCCESS",
  PASSWORD_FAILURE: "PASSWORD_FAILURE",
};

export const staticAction = {
  CONTENT_INITIATE: "CONTENT_INITIATE",
  CONTENT_SUCCESS: "CONTENT_SUCCESS",
  CONTENT_FAILURE: "CONTENT_FAILURE",
};

export const blogAction = {
  CATEGORY_LIST_INITIATE: "CATEGORY_LIST_INITIATE",
  CATEGORY_LIST_SUCCESS: "CATEGORY_LIST_SUCCESS",
  CATEGORY_LIST_FAILURE: "CATEGORY_LIST_FAILURE",

  POPULAR_TAGS_INITIATE: "POPULAR_TAGS_INITIATE",
  POPULAR_TAGS_SUCCESS: "POPULAR_TAGS_SUCCESS",
  POPULAR_TAGS_FAILURE: "POPULAR_TAGS_FAILURE",

  BLOG_LIST_INITIATE: "BLOG_LIST_INITIATE",
  BLOG_LIST_SUCCESS: "BLOG_LIST_SUCCESS",
  BLOG_LIST_FAILURE: "BLOG_LIST_FAILURE",
};

export const courtOrderAction = {
  SEARCH_BY_WORD_INITIATE: "SEARCH_BY_WORD_INITIATE",
  SEARCH_BY_WORD_SUCCESS: "SEARCH_BY_WORD_SUCCESS",
  SEARCH_BY_WORD_FAILURE: "SEARCH_BY_WORD_FAILURE",

  SEARCH_BY_LAW_INITIATE: "SEARCH_BY_LAW_INITIATE",
  SEARCH_BY_LAW_SUCCESS: "SEARCH_BY_LAW_SUCCESS",
  SEARCH_BY_LAW_FAILURE: "SEARCH_BY_LAW_FAILURE",

  SEARCH_BY_CITATION_INITIATE: "SEARCH_BY_CITATION_INITIATE",
  SEARCH_BY_CITATION_SUCCESS: "SEARCH_BY_CITATION_SUCCESS",
  SEARCH_BY_CITATION_FAILURE: "SEARCH_BY_CITATION_FAILURE",

  SEARCH_BY_PARTY_INITIATE: "SEARCH_BY_PARTY_INITIATE",
  SEARCH_BY_PARTY_SUCCESS: "SEARCH_BY_PARTY_SUCCESS",
  SEARCH_BY_PARTY_FAILURE: "SEARCH_BY_PARTY_FAILURE",

  SEARCH_BY_COURT_INITIATE: "SEARCH_BY_COURT_INITIATE",
  SEARCH_BY_COURT_SUCCESS: "SEARCH_BY_COURT_SUCCESS",
  SEARCH_BY_COURT_FAILURE: "SEARCH_BY_COURT_FAILURE",

  SEARCH_BY_CASE_NO_INITIATE: "SEARCH_BY_CASE_NO_INITIATE",
  SEARCH_BY_CASE_NO_SUCCESS: "SEARCH_BY_CASE_NO_SUCCESS",
  SEARCH_BY_CASE_NO_FAILURE: "SEARCH_BY_CASE_NO_FAILURE",

  SEARCH_BY_TOPIC_INITIATE: "SEARCH_BY_TOPIC_INITIATE",
  SEARCH_BY_TOPIC_SUCCESS: "SEARCH_BY_TOPIC_SUCCESS",
  SEARCH_BY_TOPIC_FAILURE: "SEARCH_BY_TOPIC_FAILURE",

  ORDER_CATEGORY_DROPDOWN_INITIATE:"ORDER_CATEGORY_DROPDOWN_INITIATE",
  ORDER_CATEGORY_DROPDOWN_SUCCESS: "ORDER_CATEGORY_DROPDOWN_SUCCESS",
  ORDER_CATEGORY_DROPDOWN_FAILURE: "ORDER_CATEGORY_DROPDOWN_FAILURE",

};

export const subscriptionAction = {
  GET_ALL_PLAN_INITIATE: "GET_ALL_PLAN_INITIATE",
  GET_ALL_PLAN_SUCCESS: "GET_ALL_PLAN_SUCCESS",
  GET_ALL_PLAN_FAILURE: "GET_ALL_PLAN_FAILURE",

  GET_CURRENT_PLAN_INITIATE: "GET_CURRENT_INITIATE",
  GET_CURRENT_PLAN_SUCCESS: "GET_CURRENT_SUCCESS",
  GET_CURRENT_PLAN_FAILURE: "GET_CURRENT_FAILURE",

  PURCHASE_PLAN_INITIATE: "PURCHASE_PLAN_INITIATE",
  PURCHASE_PLAN_SUCCESS: "PURCHASE_PLAN_SUCCESS",
  PURCHASE_PLAN_FAILURE: "PURCHASE_PLAN_FAILURE",


  GET_PLAN_HISTORY_INITIATE: "GET_PLAN_HISTORY_INITIATE",
  GET_PLAN_HISTORY_SUCCESS:  "GET_PLAN_HISTORY_SUCCESS",
  GET_PLAN_HISTORY_FAILURE:  "GET_PLAN_HISTORY_FAILURE",

};


export const legislationAction = {
  SEARCH_BY_LEGISLATION_INITIATE:"SEARCH_BY_LEGISLATION_INITIATE",
  SEARCH_BY_LEGISLATION_SUCCESS: "SEARCH_BY_LEGISLATION_SUCCESS",
  SEARCH_BY_LEGISLATION_FAILURE: "SEARCH_BY_LEGISLATION_FAILURE",

};

