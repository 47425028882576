import React, {useState,useEffect} from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Country, State, City }  from 'country-state-city';
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";


import {register} from "../../redux/actions/authAction";

import Header from "../header";
import Footer from "../footer";

import Login from "../../assets/images/login.png";
import SignFB from "../../assets/images/sign-fb.png";
import SignG from "../../assets/images/sign-g.png";

const initialState = {
    first_name:"",
    last_name:"",
    mobile:"",
    country:"",
    countryCode:"",
    state:"",
    stateCode:"",
    city:"",
    first_name_empty:"",
    last_name_empty:"",
    mobile_empty:"",
    country_empty:"",
    state_empty:"",
    city_empty:"",
}

const RegisterDetails = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    
    const getOTPRes = useSelector(state => state.client)
    const { otpData } = getOTPRes

    const [countryDropdown, setCountryDropdown] = useState(Country.getAllCountries());

    const [stateDropdown, setStateDropdown] = useState(State.getAllStates());
    const [stateDropdownList, setStateDropdownList] = useState([]);

    const [cityDropdown, setCityDropdown] = useState(City.getAllCities());
    const [cityDropdownList, setCityDropdownList] = useState([]);

    const [user, setUser] = useState(initialState);
    const {
        first_name, 
        last_name, 
        mobile, 
        country, 
        countryCode,
        state, 
        stateCode,
        city,
        first_name_empty,
        last_name_empty,
        mobile_empty,
        country_empty,
        state_empty,
        city_empty
    } = user;

    const [isEnabledMobile, setIsEnabledMobile] = useState(false);
    const handleMobileCheck = (e) => {
        const {name, value } = e.target
        if(isEnabledMobile) {
            setIsEnabledMobile(false)
        } else {
            setIsEnabledMobile(true)
        }
    }

    const [isEnabledTerms, setIsEnabledTerms] = useState(false);
    const handleTermsCheck = (e) => {
        const {name, value } = e.target
        if(isEnabledTerms) {
            setIsEnabledTerms(false)
        } else {
            setIsEnabledTerms(true)
        }
    }

    const handleInput = (e) => {
        const {name, value} = e.target;

        if(name === "country") {

            let getCountry = countryDropdown.filter((item) => {
                return item.isoCode == value;
            })
            setUser({...user, [name]:getCountry[0]['name'], countryCode:getCountry[0]['isoCode']});

            let stateResult = stateDropdown.filter((item) => {
                return item.countryCode == value;
            })
            setStateDropdownList(stateResult)

        } else if(name === "state") {
            
            let getState = stateDropdown.filter((item) => {
                return item.isoCode == value;
            })

            setUser({...user, [name]:getState[0]['name'], stateCode:getState[0]['isoCode']});
            
            let cityResult = cityDropdown.filter((item) => {
                return item.stateCode == value;
            })
            setCityDropdownList(cityResult)

        } else if(name=="mobile"){
            if (!/^[0-9]{0,10}$/.test(value)|| e.target.value =="e") return;
            {
              setUser((prev) => ({ ...prev, [name]: value }));
            }
        }
        else{
            setUser({...user, [name]:value});

        }
    }
    

    const handleValidation = () => {
        let flag = true;
        let first_name_empty ="";
        let last_name_empty ="";
        let mobile_empty ="";
        let country_empty ="";
        let state_empty ="";
        let city_empty ="";

        if(!first_name) {
            flag = false;
            first_name_empty = "First name field is requried";
        } else {
            if(!/\S/.test(first_name)) {
                flag = false;
                first_name_empty = "First name field is requried";
            }
        }
        if(!last_name) {
            flag = false;
            last_name_empty = "Last name field is requried";
        } else {
            if(!/\S/.test(last_name)) {
                flag = false;
                last_name_empty = "Last name field is requried";
            }
        }

        if(isEnabledMobile) {
            if(!mobile) {
                flag = false;
                mobile_empty = "Mobile field is requried";
            }
            if(/^\d*$/.test(mobile)) {} else {
                flag = false;
                mobile_empty = "Please enter Numeric value";
            }
        }

        if(!country) {
            flag = false;
            country_empty = "Country field is requried";
        }

        if(!state) {
            flag = false;
            state_empty = "State field is requried";
        }

        if(!city) {
            flag = false;
            city_empty = "City field is requried";
        }

        if(flag) {
            if(!isEnabledTerms) {
                flag = false;
                toast.error('Please accept the Terms and Condition');
            }
        }
        setUser({
            ...user, 
            first_name_empty:first_name_empty, 
            last_name_empty:last_name_empty,
            mobile_empty:mobile_empty,
            country_empty:country_empty,
            state_empty:state_empty,
            city_empty:city_empty
        })
        return flag;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let checkValidation = handleValidation();
        if(checkValidation) {

            let getCountry = countryDropdown.filter((item) => {
                return item.name == country;
            })

            let reqData = {
                email: otpData.email,
                firstName:first_name,
                lastName:last_name,
                phoneNumber:mobile,
                country:{
                    "name" : country,
                    "isoCode" : countryCode,
                },
                state:{
                    "name" : state,
                    "isoCode" : stateCode,
                    "countryCode" : countryCode
                },
                city:{
                    "name":city,
                    "isoCode" : stateCode
                },
                countryCode:"",
                countryFlag:"",
                image:""
            }
            //console.log("reqData", reqData)
            dispatch(register(reqData))
            .then((res)=>{
                console.log(res);
                if(res.status == 200) {
                    toast.success('Registered Successfully.');
                    navigate("/login");
                } else {
                    toast.error('Something went wrong! Please try after sometime.');
                }
            })
            .catch((err)=> {
                console.log(err)
            })
        }
    }

    const handlePhoneChange = (value, data, event, formattedValue) => {
        // setCode(data.dialCode)
        setUser({...user, mobile: value, countryCode: data.dialCode });
      };
    

      console.log(stateDropdownList,'SSSSSSSSS')


    return (
        <>
            <Toaster position="top-right" gutter={8} containerClassName="mytoast" toastOptions={{ error:{duration: 3000, style:{background:'#be1313', color:'#fff'}}}} />
            <section>
                <div className="login-page">
                    <div className="login-left">
                        <img src={Login} alt="login" />
                    </div>
                    <div className="login-right">
                        <figcaption>
                            <h1>Complete Your Profile</h1>
                            <p>Free to Read, Green Judgments (India, Bangladesh, Pakistan,Sri Lanka, Nepal, Bhutan). Download & Print - Subscribe Now!</p>
                        </figcaption>

                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control" name="first_name" value={first_name} placeholder="Enter First Name" onChange={handleInput}/>
                                        <span className="error">{first_name_empty}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control" name="last_name" value={last_name} placeholder="Enter Last Name" onChange={handleInput} />
                                        <span className="error">{last_name_empty}</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="CheckBox">Please enter your mobile number if you intend to use SAREL Mobile App
                                            <input type="checkbox" onChange={handleMobileCheck}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        <PhoneInput
                            inputProps={{
                              maxlength: countryCode.length + 14,
                            }}
                            country={"in"}
                            value={mobile}
                            countryCodeEditable={false}
                            enableSearch={true}
                            onChange={handlePhoneChange}                          />
                                        <span className="error">{mobile_empty}</span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label>Country</label>
                                        <select className="form-control" name="country" onChange={handleInput}>
                                            <option value="">--Select--</option>
                                            {
                                                countryDropdown && countryDropdown?.filter((ele)=>ele.name =="India"|| ele.name =="Bangladesh"|| ele.name =="Pakistan"|| ele.name =="Bhutan"|| ele.name =="Sri Lanka"|| ele.name =="Nepal")?.map((item , index) => {
                                                    //return console.log(item.name)
                                                    return <option key={index} value={ item.isoCode }> { item.name } </option>
                                                })
                                            }
                                        </select>

                                        <span className="error">{country_empty}</span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label>State/Province</label>
                                        <select className="form-control" name="state" onChange={handleInput}>
                                            <option value="">--Select--</option>
                                            {
                                                stateDropdownList && stateDropdownList.map((item, index) => {
                                                    return <option key={index} data-name={item.name} value={ item.isoCode }> { item.name } </option>
                                                })
                                            }
                                        </select>

                                        <span className="error">{state_empty}</span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label>City</label>
                                        <select className="form-control" name="city" onChange={handleInput}>
                                            <option value="">--Select--</option>
                                            {
                                                cityDropdownList && cityDropdownList.map((item, index) => {
                                                    return <option key={index} value={ item.name }> { item.name } </option>
                                                })
                                            }
                                        </select>

                                        <span className="error">{city_empty}</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="CheckBox">I Accept the <span>TERMS & CONDITIONS</span>
                                            <input type="checkbox" onChange={handleTermsCheck}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>                    
            
                            <button type="submit" className="button">Start Free Access</button>

                            <div className="register">
                                <p>Already a member? <Link to="/login"> Login</Link></p>
                            </div>
                            <div className="peragraph">
                            </div>
{/* 
                            <div className="sign-fb">
                                <a href="#"><img src={SignFB} alt="Sign Facebook" /></a>
                                <a href="#"><img src={SignG} alt="Sign Google" /></a>
                            </div> */}
                        </form>
                    </div>
                </div>
            </section>
            
        </>
    );
}

export default RegisterDetails