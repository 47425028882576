import React, { useState } from "react"
import { useDispatch } from "react-redux";
import toast,{ Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

import { sentForgotOTP } from "../../redux/actions/authAction";

import Header from "../header";
import Footer from "../footer";

import Login from "../../assets/images/login.png";

const initialState = {
    email : "",
    email_empty : "",
}

const ForgotPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [user, setUser] = useState(initialState);
    const { email, email_empty } = user

    const handleInput = (e) => {
        const {name, value} = e.target
        setUser({...user, [name]:value})
    }

    const handleValidation = () => {
        let flag = true;
        let email_empty = '';

        if(!email) {
            flag = false
            email_empty = "Email field is required"
        } else {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!email.match(mailformat)) {
                flag = false;
                email_empty = "Invalid email address"
            }
        }
        setUser({...user, email_empty:email_empty})

        return flag;
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const checkValidation = handleValidation()
        if(checkValidation) {
            let reqData = {email:email}

            dispatch(sentForgotOTP(reqData))
            .then((res) => {
                console.log("web", res)
                if(res.status == 200) {
                    toast.success("OTP has been send to your email")
                    navigate("/forgot-password/otp")
                } else {
                    toast.error(res.message)
                }
            })
            .catch((error) => {
                console.log("error", error)
                toast.error(error.response.data.message)
            })
        }
    }

    return (
        <>
            <Toaster position="top-right" gutter={8} containerClassName="mytoast" toastOptions={{ error:{duration: 3000, style:{background:'#be1313', color:'#fff'}}}} />
            <section>
                <div className="login-page">
                    <div className="login-left">
                        <img src={Login} alt="login" />
                    </div>
                    <div className="login-right">
                        <figcaption>
                            <h1>Forgot Password</h1>
                            <p>No Problem! Just provide your e-mail address and we’ll send you a reset OTP.</p>
                        </figcaption>

                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Email Address</label>
                                <input type="text" name="email" value={email} placeholder="Enter Email Address" className="form-control" onChange={handleInput}/>
                            </div>

                            <span className="error">{email_empty}</span>
            
                            <button type="submit" className="button">Send OTP</button>
                            <Link to="/login" className="button active">Skip</Link>
                            
                        </form>
                    </div>
                </div>
            </section>
            
        </>
    );
}

export default ForgotPassword