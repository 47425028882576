import { legislationAction } from "../actionType"

const initialState = {
  legislationList:[],
  loader: false,
  loginStatus: false
}

export default function legislationReducer(state = initialState, {type, payload}) {
  switch(type) {
    
    case legislationAction.SEARCH_BY_LEGISLATION_INITIATE: {
      return {
        ...state, loader: true, legislationList: []
      }
    }
    case legislationAction.SEARCH_BY_LEGISLATION_SUCCESS: {
      return {
        ...state, loader: false, legislationList: payload.data
      }
    }
    case legislationAction.SEARCH_BY_LEGISLATION_FAILURE: {
      return {
        ...state, loader: false
      }
    }


    default: 
      return state
  }
}