import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { login } from "../../redux/actions/authAction";

import Header from "../header";

import Login from "../../assets/images/login.png";
import SignFB from "../../assets/images/sign-fb.png";
import SignG from "../../assets/images/sign-g.png";
import { useEffect } from "react";
import { profileDetail } from "../../redux/actions/profileAction";

const initialState = {
  email: "",
  password: "",
  email_empty: "",
  password_empty: "",
};

const LoginAuth = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [user, setUser] = useState(initialState);
  const { email, password, email_empty, password_empty } = user;

  const [password1Shown, setPassword1Shown] = useState(false);

  const toggle1PasswordVisiblity = () => {
    setPassword1Shown(password1Shown ? false : true);
  };

  useEffect(() => {
    if (window.localStorage.getItem("rememeberMe")) {
      let remember = JSON.parse(window.localStorage.getItem("rememeberMe"));
      if (remember) {
        setUser({
          ...user,
          email: remember.email,
          password: remember.password,
        });
      }
    }
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const [isRemember, setIsRemember] = useState(false);
  const handleRemember = () => {
    if (isRemember) {
      setIsRemember(false);
    } else {
      setIsRemember(true);
    }
  };

  const handleValidation = () => {
    let flag = true;
    let email_empty = "";
    let password_empty = "";

    var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!email) {
      flag = false;
      email_empty = " Email field is required";
    }
    if (!regex.test(email)) {
      flag = false;
      email_empty = "Please enter valid email";
    }

    if (!password) {
      flag = false;
      password_empty = "Password field is required";
    }
    setUser({ ...user, email_empty, password_empty });
    return flag;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let checkValidation = handleValidation();
    if (checkValidation) {
      const reqData = { email, password };
      dispatch(login(reqData))
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            if (isRemember) {
              window.localStorage.setItem(
                "rememeberMe",
                JSON.stringify(reqData)
              );
            }
            dispatch(profileDetail()).then((res) => {
              if (res.status !== 200) {

                        localStorage.removeItem("clientLogin");


              }})
      
            navigate("/");
          } else if (res.status == 400) {
            toast.error(res.message);
          } else {
            toast.error("Something went wrong! Please try after sometime.");
          }
        })
        .catch((err) => {
          //console.log('error', err)
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <>
      <Toaster
        position="top-right"
        gutter={8}
        containerClassName="mytoast"
        toastOptions={{
          error: {
            duration: 3000,
            style: { background: "#be1313", color: "#fff" },
          },
        }}
      />
      <section>
        <div className="login-page">
          <div className="login-left">
            <img src={Login} alt="login" />
          </div>
          <div className="login-right">
            <figcaption>
              <h1>
                Log In to <span>ELDESA</span>
              </h1>
              <p>
                Please login using login credentials sent to your registered
                mobile number
              </p>
            </figcaption>

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Login ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  placeholder="Eg- test@yourmail.com"
                  onChange={handleInput}
                />
                <span>{email_empty}</span>
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type={password1Shown ? "text" : "password"}
                  className="form-control"
                  name="password"
                  value={password}
                  placeholder="Enter Password"
                  onChange={handleInput}
                />
                <span
                  onClick={toggle1PasswordVisiblity}
                  className={password1Shown ? "Icon cross" : "Icon"}
                >
                  {password1Shown ? (
                    <i className="fa fa-eye"></i>
                  ) : (
                    <i class="fa fa-eye-slash" aria-hidden="true"></i>
                  )}
                </span>
                <span>{password_empty}</span>
              </div>
              <div className="form-group form-group-checkBox">
                <label className="CheckBox">
                  Remember me
                  <input type="checkbox" onChange={handleRemember} />
                  <span className="checkmark"></span>
                </label>
                <div className="Checkboxs">
                  <Link to="/forgot-password">Lost your password?</Link>
                </div>
              </div>

              <button type="submit" className="button">
                Login
              </button>

              <div className="register">
                <p>
                  Not a member? <Link to="/signup">Register now</Link>
                </p>
              </div>
              <div className="peragraph"></div>

              {/* <div className="sign-fb">
                <a href="#">
                  <img src={SignFB} alt="fb signup" />
                </a>
                <a href="#">
                  <img src={SignG} alt="google signup" />
                </a>
              </div> */}
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginAuth;
