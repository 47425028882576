import React, { useEffect, useState } from "react";

import Header from "./header";
import Footer from "./footer";
import BG from "../assets/images/BG.png";
import Map from "../assets/images/map.png";
import Vector from "../assets/images/Vector.png";
import ArrowRight from "../assets/images/arrow-right.png";
import Icon from "../assets/images/icon.png";
import LatestUpdate from "../assets/images/latest-update.png";
import FreeTrail from "../assets/images/free-trail.png";
import Tick from "../assets/images/tick.png";
import Blog from "../assets/images/blog1.png";
import MobileApp from "../assets/images/mobile-app.png";
import Apple from "../assets/images/apple.png";
import Android from "../assets/images/android.png";
import ArrowWhite from "../assets/images/Arrow-white.png";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPlan } from "../redux/actions/subscriptionAction";
import { blogListing } from "../redux/actions/blogAction";
import { dateTimeFormat } from "./helpers/dateTimeFormat";

const Home = () => {

    const [searchState, setSearchSate] =useState(false)
  const getProfileData = useSelector((state) => state.profile);

  const {state} = useLocation()

  const { subscriptionList } = useSelector(
    (state) => state.subscriptionReducer
  );

  const getBlogData = useSelector((state) => state.blogs);
  const { blogData } = getBlogData;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllPlan());

    const reqData = {
      keywords: "",
      category: "",
      tags: "",
      page: 1,
      limit: 10,
    };
    dispatch(blogListing(reqData));
  }, []);



  useEffect(()=>{
    if(state=="top"){
    const element = document.getElementById('JudgementSearch')
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }}

  },[state])




  return (
    <>
 
      <section>
        <div className="banner">
          <div className="banner-left">
            <img src={BG} alt="banner" />
          </div>
          <div className="banner-right">
            <h1>
              Because The Earth <br /> <span>Needs Justice</span>
            </h1>
            <figure>
              <img src={Map} alt="map" />
            </figure>
          </div>
        </div>
      </section>


      <section id="JudgementSearch">
          <div className="dashboard">
            <div className="container">
              <aside>
                <h2>ELDESA Online Dashboard</h2>
                <ul>
                  <li>
                    <Link to="/user-word-search">
                      <p>
                        Word <br /> Search
                      </p>
                      <span>
                        <img src={ArrowWhite} alt="Arrow White" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-law-search">
                      <p>
                        Find by <br />
                        Law
                      </p>
                      <span>
                        <img src={ArrowWhite} alt="Arrow White" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-citation-search">
                      <p>
                        Find by <br />
                        Citation
                      </p>
                      <span>
                        <img src={ArrowWhite} alt="Arrow White" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-party-search">
                      <p>
                        Find by <br />
                        Party Name
                      </p>
                      <span>
                        <img src={ArrowWhite} alt="Arrow White" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-courtName-search">
                      <p>
                        Find by <br />
                        Court Name
                      </p>
                      <span>
                        <img src={ArrowWhite} alt="Arrow White" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-caseNumber-search">
                      <p>
                        Find by <br />
                        Case No.
                      </p>
                      <span>
                        <img src={ArrowWhite} alt="Arrow White" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-topic-search">
                      <p>
                        Find by <br />
                        Topic
                      </p>
                      <span>
                        <img src={ArrowWhite} alt="Arrow White" />
                      </span>
                    </Link>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
      
      </section>

      <section>
        <div className="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-5">
                <div className="about-left">
                  <h2>
                    It's our world. These <br />
                    are our laws. This is <br />
                    our work. Join us.
                  </h2>
                </div>
              </div>
              <div className="col-7">
                <div className="about-right">
                  <span className="about-img">
                    <img src={Vector} alt="Vector" />
                  </span>
                  <h2>About ELDESA</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur dipiscing elit.
                    Nullam a volutpat lacus. Duis est arcu, tincidunt vel
                    tristique id, dignissim ac metus. Pellent esque semper
                    ligula massa, ut fringilla ipsum ullamcorper et. Nunc eleme
                    ntum consequat leo. Suspendisse egestas felis nibh, id
                    mattis urna sempe r ornare. Sed hendrerit nulla nulla
                  </p>
                  <a href="read-more.html">
                    Read More <img src={ArrowRight} alt="arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="latest-update">
          <div className="latest-update-left">
            <span>
              <img src={Vector} alt="Vector" />
            </span>
            <h2>Latest Updates</h2>

            <aside>
              <h3>Latest Judgements</h3>
              <ul>
                <li>
                  <span>
                    <img src={Icon} alt="icon" />
                  </span>
                  Administrative Tribunals
                </li>
                <li>
                  <span>
                    <img src={Icon} alt="icon" />
                  </span>
                  Andhra Pradesh HC (Ceased w.e.f. 02-06-2014)
                </li>
              </ul>
            </aside>

            <aside>
              <h3>Legislations</h3>
              <ul>
                <li>
                  <span>
                    <img src={Icon} alt="icon" />
                  </span>
                  Administrative Tribunals
                </li>
                <li>
                  <span>
                    <img src={Icon} alt="icon" />
                  </span>
                  Andhra Pradesh HC (Ceased w.e.f. 02-06-2014)
                </li>
              </ul>
            </aside>
          </div>
          <div className="latest-update-right">
            <img src={LatestUpdate} alt="latest-update" />
          </div>
        </div>
      </section>

      <section>
        <div className="get-free-trail">
          <div className="free-trail">
            <img src={FreeTrail} alt="free-trail" />
          </div>
          <div className="free-trail-roght">
            <span>
              <img src={Vector} alt="Vector" />
            </span>
            <h3>
              Start your 1 month free <br /> trial now
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />{" "}
              Nullam a volutpat
            </p>
            <a href="#" className="Button">
              Start Free Trial
            </a>
          </div>
        </div>
      </section>

      <section>
        <div className="free-trails">
          <figcaption>
            <span>
              <img src={Vector} alt="Vector" />
            </span>
            <h3>
              Choose the right price plan <br /> for your needs
            </h3>
            <p>Choose from our affordable 3 packages</p>
          </figcaption>

          <div className="container">
            <div className="row">
              {subscriptionList?.map((plan) => (
                <div className="col-4">
                  <div className="your-plan">
                    <h4>{plan?.name}</h4>
                    <h2>
                      ₹{plan?.actualPrice} <span> / year</span>
                    </h2>
                    <p>Excluding GST</p>
                    <ul>
                      <li>
                        <span>
                          <img src={Tick} alt="tick" />
                        </span>{" "}
                        Indian Case Law
                      </li>
                      <li>
                        <span>
                          <img src={Tick} alt="tick" />
                        </span>{" "}
                        Supreme Court of India
                      </li>
                      <li>
                        <span>
                          <img src={Tick} alt="tick" />
                        </span>{" "}
                        All High Courts
                      </li>
                    </ul>
                    <a className="button" href="#">
                      Get Started
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="blogs">
          <figcaption>
            <span>
              <img src={Vector} alt="Vector" />
            </span>
            <h3>
              Read our blogs to learn more <br /> about environmental law
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a{" "}
              <br /> volutpat lacus.
            </p>
          </figcaption>
          <div className="container">
            <div className="row">
              {blogData && blogData.result?.length > 0
                ? blogData.result.map((item) => (
                    <div className="col-6">
                      <div className="blog-card">
                        <figure>
                          <img src={item.image} alt="blog" />
                        </figure>
                        <figcaption>
                          <h4>
                            <span> {item.author} </span> •{" "}
                            {dateTimeFormat(item.createdAt)}{" "}
                          </h4>
                          <h3> {item.title} </h3>
                          <p>
                            {item?.blog?.slice(0, 150)}

                            <Link to="/blog-details" state={item}>
                              Read More
                            </Link>
                          </p>
                        </figcaption>
                        <div className="blog-button">
                          {item.tags ? (
                            <button> {item.tags.tags} </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="mobile-app">
          <div className="container">
            <div className="mobile-app-right">
              <img src={MobileApp} alt="mobile app" />
            </div>

            <div className="mobile-app-left">
              <span>
                <img src={Vector} alt="Vector" />
              </span>
              <h2>Download our mobile app</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />{" "}
                Nullam a volutpat lacus.
              </p>
              <div>
                <a href="#">
                  <img src={Apple} alt="apple" />
                </a>
                <a href="#">
                  <img src={Android} alt="android" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
