import { subscriptionAction } from "../actionType"

const initialState = {
  currentPlan: {},
  subscriptionList:[],
  planHistory:[],
  loader: false,
  loginStatus: false
}

export default function subscriptionReducer(state = initialState, {type, payload}) {
  switch(type) {
    
    case subscriptionAction.GET_ALL_PLAN_INITIATE: {
      return {
        ...state, loader: true, subscriptionList: []
      }
    }
    case subscriptionAction.GET_ALL_PLAN_SUCCESS: {
      return {
        ...state, loader: false, subscriptionList: payload.data
      }
    }
    case subscriptionAction.GET_ALL_PLAN_FAILURE: {
      return {
        ...state, loader: false
      }
    }


    case subscriptionAction.GET_CURRENT_PLAN_INITIATE: {
        return {
          ...state, loader: true, currentPlan: {}
        }
      }
      case subscriptionAction.GET_CURRENT_PLAN_SUCCESS: {
        return {
          ...state, loader: false, currentPlan: payload.data
        }
      }
      case subscriptionAction.GET_CURRENT_PLAN_FAILURE: {
        return {
          ...state, loader: false
        }
      }


      case subscriptionAction.GET_PLAN_HISTORY_INITIATE: {
        return {
          ...state, loader: true, planHistory: []
        }
      }
      case subscriptionAction.GET_PLAN_HISTORY_SUCCESS: {
        return {
          ...state, loader: false, planHistory: payload.data
        }
      }
      case subscriptionAction.GET_PLAN_HISTORY_FAILURE: {
        return {
          ...state, loader: false
        }
      }

  
  

    default: 
      return state
  }
}