import React from 'react'
import Header from '../header'
import Footer from '../footer'

const ViewReceipt = () => {
  return (
    <>
    <Header />

    <section class="current-plan">
        <div class="container">
            <div class="current-plan-area">
                <figcaption>
                    <h4>Receipt</h4>
                    <a href="#">Download Receipt</a>
                </figcaption>

                <div class="TableList">
                    <table style={{width:"100%" }}>
                        <thead>
                            <tr>
                                <th>Plan Name</th>
                                <th>Reference Number</th>
                                <th>Payment Due</th>
                                <th>Paymnet Date</th>                                
                                <th>Transaction ID</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1 Year Plan</td>
                                <td>0215-15450-5956</td>
                                <td>04/01/2024</td>
                                <td>08/01/2024</td>
                                <td>15450-595656</td>
                            </tr>
                                                       
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <Footer />

    </>
  )
}

export default ViewReceipt